import React, { useState } from 'react'
import { signIn } from 'next-auth/client'
import api from '../../pages/api/index'
import _ from 'lodash'
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export default function loginForm({session}) {

    const [email, setEmail] = useState('')
    const [errorLogin, setErrorLogin] = useState({ status: false, error: null })
    const [loading, setLoading] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault()

        setLoading(true)
        setErrorLogin(false)

        let cleanEmail = _.trimStart(email)
        cleanEmail = _.trimEnd(cleanEmail)
        cleanEmail = _.toLower(cleanEmail)
        //console.log('cleanEmail', cleanEmail)

        try {
            const { data } = await api.login(cleanEmail)

            if (data.data.Login?.status) {

                const role = data.data.Login.user.role
                let url = null

                if (role == 1 || role == 2)
                    url = `${process.env.NEXTAUTH_URL}/evento/${data.data.Login.user.category.slug}`

                if (role == 3)
                    url = `${process.env.NEXTAUTH_URL}/admin/${data.data.Login.user.category.slug}`

                if (role == 4)
                    url = `${process.env.NEXTAUTH_URL}/speaker/${data.data.Login.user.category.slug}`

                signIn('credentials', {
                    email: cleanEmail,
                    access_token: data.data.Login.access_token,
                    refresh_token: data.data.Login.refresh_token,
                    user_id: data.data.Login.user.id,
                    category_id: data?.data?.Login?.user?.category?.id,
                    category_slug: data?.data?.Login?.user?.category?.slug,
                    category_title: data.data.Login.user.category.title,
                    group_id: data?.data?.Login?.user?.group?.id,
                    role: data.data.Login.user.role,
                    activity_url: data?.data?.Login?.user?.activity?.url,
                    callbackUrl: url


                })
            }

            !data.data.Login.status && setErrorLogin({ status: true, error: 'No se encontro al usuario.' })

        } catch (err) {
            console.log('ERROR', err)
            setErrorLogin({ status: true, error: err })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                    <div className="mt-1">

                        <input
                            value={email}
                            placeholder="Escribe tu correo para continuar"
                            onChange={(e) => setEmail(e.target.value)}
                            id="email" name="email" type="email" autoComplete="email"
                            required
                            className="appearance-none block w-full px-3 py-2 border-none bg-gray-200 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />

                    </div>
                </div>

                <div className="justify-center flex">
                    <button type="submit" className="flex justify-center py-2 px-8 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Unirse
                        { (loading || session) && 
                            <Loader className="ml-1 mt-1" type="ThreeDots" color="#FFFFFF" height={20} width={20} />
                        }
                    </button>
                </div>
            </form>
            {/* <button onClick={methodDoesNotExist}>Break the world</button> */} 
            {errorLogin.status &&
                <>
                    <div className="rounded-md bg-red-50 p-4 mt-2">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                {/* <!-- Heroicon name: x-circle --> */}
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div >
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                    {errorLogin.error}
                                </h3>
                            </div>
                        </div >
                    </div >
                </>
            }
        </>
    )
}

