import { useState, useEffect } from 'react'

import Layout from '../components/common/layout'
import LoginForm from '../components/forms/loginForm'
import { getSession, session } from 'next-auth/client'
import { useRouter } from 'next/router'

export default function Home({session}) {

  const router = useRouter()


  useEffect(() => {
    if( session )
      router.push(`/evento/${session.user.image.category_slug}`)
  }, [])
  
  return (
    <Layout>
      <div className="bg-center bg-no-repeat bg-cover" style={{backgroundImage: 'url(/img/junave-bg.jpg)'}}>
        
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">

          <div className="mx-auto sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">

              <div className="mb-8 sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-40 w-auto" src="/img/stepping-up.png" alt="Workflow" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Únete al evento</h2>
              </div>

               <LoginForm session={session} />

            </div>
          </div>
          
        </div>
      </div>
    </Layout>
  )
}

export async function getServerSideProps(context) {
  
  const session = await getSession(context)
  
  if( session ) {
    let category_slug = session.user.image.category_slug

    context.res.setHeader("location", `/evento/${category_slug}`)
    context.res.statusCode = 302
    context.res.end()
    return {
      props: {
          session: null
        }
    }
  }
    
  return {
    props: {
        session
      }
  }
}
